// ==================================================================================== //
// DEFINE PALETTES


// palettes from lospec
const palettes = {
    voltage_warning: {
        link: 'https://lospec.com/palette-list/voltage-warning',
        light: '#d4c9c3',
        mid1: '#d3ae21',
        mid2: '#635650',
        dark: '#1c1412',
    },
    st_gameboy_deluxe: {
        link: 'https://lospec.com/palette-list/st-gameboy-deluxe',
        light: '#f4f5e9',
        mid1: '#a8bf4c',
        mid2: '#442a8c',
        dark: '#09091a',
    },
    optimized_grayscale: {
        link: 'https://lospec.com/palette-list/optimized-grayscale-4',
        light: '#dfdfdf',
        mid1: '#9f9f9f',
        mid2: '#606060',
        dark: '#202020',
    },
    bicycle: {
        link: 'https://lospec.com/palette-list/bicycle',
        light: '#f0f0f0',
        mid1: '#8f9bf6',
        mid2: '#ab4646',
        dark: '#161616',
    },
    deadland_2bit: {
        link: 'https://lospec.com/palette-list/deadland-2-bit',
        light: '#d9e6fa',
        mid1: '#8bb5b3',
        mid2: '#7b6149',
        dark: '#330126',
    },
    sunrise_gb: {
        link: 'https://lospec.com/palette-list/sunrisegb',
        light: '#ffddc9',
        mid1: '#ff5d18',
        mid2: '#a71442',
        dark: '#090d21',
    },
    moss_tone_2bit: {
        link: 'https://lospec.com/palette-list/moss-tone-2-bit',
        light: '#fdf4e9',
        mid1: '#93b987',
        mid2: '#585460',
        dark: '#2e2f35',
    },
};


// function that picks random properties
const randomProp = obj => Object.keys(obj)[(Math.random() * Object.keys(obj).length) | 0];


// function that changes the color of an svg embedded in an object element
function svgChangeColor(color, svg_object, svgclass) {
    // fetch svg elements
    var elements = svg_object.contentDocument.getElementsByClassName(svgclass);
    // iterate through the elements and change every fill attribute
    for (var i = 0; i < elements.length; i++) {
        elements[i].style.fill = color;
    }
}


let dark_mode;
const current_pal = palettes[randomProp(palettes)];

// reference logo element 
var sun_logo = document.querySelector('#sun-logo');
var sigil = document.querySelector("#background-image");


// ==================================================================================== //
// APPLY THEMES
// very gross but ehh


// inital setup 
if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    dark_mode = true;
    console.log("dark mode:", dark_mode);
}

// everything except svgs
if (dark_mode) {
    document.documentElement.style.setProperty('--bg', current_pal['dark']);
    document.documentElement.style.setProperty('--mid1', current_pal['mid2']);
    document.documentElement.style.setProperty('--mid2', current_pal['mid1']);
    document.documentElement.style.setProperty('--main', current_pal['light']);

} else {
    document.documentElement.style.setProperty('--bg', current_pal['light']);
    document.documentElement.style.setProperty('--mid1', current_pal['mid1']);
    document.documentElement.style.setProperty('--mid2', current_pal['mid2']);
    document.documentElement.style.setProperty('--main', current_pal['dark']);
    
}

// wait for the logo to load
sun_logo.addEventListener('load', () => {
    // apply theme
    if (dark_mode) {
        svgChangeColor(current_pal['light'], sun_logo, 'dummy'); 
    } else {
        svgChangeColor(current_pal['dark'], sun_logo, 'dummy');       
    }
});

// wait for the bgimgs to load
sigil.addEventListener('load', () => {
    // apply theme
    if (dark_mode) {
        svgChangeColor(current_pal['mid2'], sigil, 'dummy'); 
    } else {
        svgChangeColor(current_pal['mid1'], sigil, 'dummy'); 
    }
    
})

// ==================================================================================== //
// SWITCH THEMES


// switch the theme
function switchTheme() {

    if (dark_mode) {
        document.documentElement.style.setProperty('--bg', current_pal['light']);
        document.documentElement.style.setProperty('--mid1', current_pal['mid1']);
        document.documentElement.style.setProperty('--mid2', current_pal['mid2']);
        document.documentElement.style.setProperty('--main', current_pal['dark']);
        svgChangeColor(current_pal['dark'], sun_logo, 'dummy');
        svgChangeColor(current_pal['mid1'], sigil, 'dummy');
        dark_mode = false;

    } else {
        document.documentElement.style.setProperty('--bg', current_pal['dark']);
        document.documentElement.style.setProperty('--mid1', current_pal['mid2']);
        document.documentElement.style.setProperty('--mid2', current_pal['mid1']);
        document.documentElement.style.setProperty('--main', current_pal['light']);
        svgChangeColor(current_pal['light'], sun_logo, 'dummy');
        svgChangeColor(current_pal['mid2'], sigil, 'dummy'); 
        dark_mode = true;

    }
}


document.getElementById("switch-theme").addEventListener("click", switchTheme);